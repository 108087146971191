import {createGlobalStyle} from 'styled-components';
import './flaticon.css';

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }
  
  .black-sticky .sassminimal_navbar{
    background: #7524ab;
  }
  
  .black-sticky.sticky-nav-active .sassminimal_navbar{
    background: white !important;
  }
  
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .reuseModalHolder {
    padding: 0 !important;

    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.9) !important;

      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
  }

  button.modalCloseBtn {
    position: fixed !important;
    z-index: 999991 !important;
    background-color: transparent !important;
    top: 10px !important;
    right: 10px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    padding: 0 !important;

    span.btn-icon {
      font-size: 22px !important;
      transform: rotate(45deg) !important;
    }

    &.alt {
      border-radius: 50% !important;
      z-index: 999999 !important;
      padding: 0 !important;
      transition: all 0.3s ease !important;
      top: 25px !important;
      right: 30px !important;
      min-width: 40px !important;
      min-height: 40px !important;

      span.btn-icon {
        font-size: 20px !important;
      }

      &:hover {
        opacity: 0.88 !important;
      }
    }
  }

  .hide {
    display: none;
  }

  .flex {
    display: flex;
  }

  .bg-primary {
    background-color: #5B1375 !important;
    color: #fff;
  }

  .h6, h6 {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
  }

  .mt3 {
    margin-top: 3rem !important;
  }

  .btn-primary {
    width: 100%;
    margin: auto;
    border: 0;
    background: #5B1375;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 20px 15px 20px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    cursor: pointer;

  }

  .btn-primary:hover {
    background: #23374d;
  }

  .alert {
    width: 100%;
    font-size: 15px;
    padding: 10px 20px 10px 20px;
    text-align: left;
    font-weight: 500;
    margin-top: 10px;
  }

  .alert-danger {
    background: indianred;
    border: 1px solid #ab0202;
    color: white;
  }

  .text-primary {
    color: #1FC27C !important;
    font-weight: 500;
    font-size: 26px;
  }

  .text-primary small {
    color: #313131 !important;
    font-size: 20px !important;
    font-weight: 100;
  }

  .mb-05 {
    margin-bottom: 10px;
  }

  .mt-15 {
    margin-top: 25px;
  }

  .mb-15 {
    margin-top: 25px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .half {
    width: 49% !important;
    float: left;
  }

  .ml2 {
    margin-left: 2% !important;
  }

  .gatsby-image-wrapper img {
    width: 85% !important;
  }

  .mobile-fixed-links {
    display: none !important;
  }

  @media (max-width: 999px) {
    .mobile-fixed-links {
      display: block !important;
    }
  }
  @media (max-width: 600px) {

    .trial {
      margin-right: 0 !important;
    }

    #banner_section .Input {
      font-size: 14px;
      padding: 15px;
      padding-left: 0;
    }

    #banner_section .dial-select {
      min-width: 105px;
    }
  }
`;
